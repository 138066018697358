/* WARNING: Auto-generated by the import-fonts script. Changes here will be overwritten. */

@font-face {
  font-family: "Geologica";
  src: url("./GeologicaThinRoman-Bold/geologica-bold.woff2") format("woff2"),
    url("./GeologicaThinRoman-Bold/geologica-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: url("./GeologicaThinRoman-Regular/geologica-regular.woff2") format("woff2"),
    url("./GeologicaThinRoman-Regular/geologica-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: url("./GeologicaThinRoman-Light/geologica-light.woff2") format("woff2"),
    url("./GeologicaThinRoman-Light/geologica-light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
